/* General body styling */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #0e0e14; /* Dark background for the entire app */
    color: #e0e0e0; /* Light font color for better contrast */
  }
  
  /* Container settings for main content */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  /* Card Styling for all cards across the app */
  .card {
    background-color: #1e1e30; /* Darker gray background for cards */
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
  }
  
  /* Typography for Headings */
  h2, h3, h4 {
    color: #f0f0f0;
    margin-bottom: 1rem;
  }
  
  /* Lists */
  ul {
    list-style-type: none;
    padding-left: 30px; /* Increased padding to move list items further to the right */
    margin-left: 10px; /* Added margin to move the whole list to the right */
  }
  
  li {
    margin-bottom: 1.5rem;
    padding-left: 10px; /* Padding for individual list items to give more space from bullet */
  }
  
  /* Summary Styling for Details */
  details summary {
    cursor: pointer;
    font-weight: bold;
    color: #e0e0e0;
    margin-bottom: 0.5rem;
  }
  
  /* Buttons Styling */
  button {
    background-color: #3a3f58; /* Darker blue-gray button */
    color: #e0e0e0;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #5a5f78; /* Lighter on hover */
  }
  
  .button.contrast {
    background-color: #f05454; /* Red color for contrast buttons */
    color: #fff;
  }
  
  .button.contrast:hover {
    background-color: #c43b3b;
  }
  
  /* Input Fields Styling */
  input:not([class*="Mui"]), 
  select:not([class*="Mui"]), 
  textarea:not([class*="Mui"]) {
    background-color: #161625;
    color: #e0e0e0;
    border: 1px solid #444;
    padding: 0.5rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    width: 100%;
  }
  
  input:not([class*="Mui"]):focus, 
  select:not([class*="Mui"]):focus, 
  textarea:not([class*="Mui"]):focus {
    outline: none;
    border-color: #5a5f78;
  }
  
  /* Sensor Chart Styling */
  .sensor-chart-container {
    padding: 1rem;
    border: 1px solid #444;
    background-color: #1e1e30;
    border-radius: 8px;
    margin-top: 1rem;
  }
  
  /* Navigation Styling (if applicable) */
  nav {
    background-color: #1e1e30;
    padding: 1rem;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  ul {
    list-style-type: none;
  }
  
  nav ul {
    list-style: none;
    padding-left: 30px; /* Increased padding for better spacing in the navigation bar */
    margin-left: 10px; /* Added margin to move the entire list to the right */
  }
  
  nav ul li {
    margin-bottom: 1rem;
  }
  
  nav ul li a {
    color: #e0e0e0;
    text-decoration: none;
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }
  
  /* Link Styling */
  a {
    color: #f05454; /* Highlight links */
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Flags and Severity Colors */
  .flag-card {
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  
  .flag-card p {
    margin-bottom: 0.5rem;
  }
  
  .flag-active {
    color: #00c853; /* Green for active flags */
  }
  
  .flag-inactive {
    color: #ffa726; /* Orange for inactive flags */
  }
  
  .flag-severity {
    color: #f05454; /* Red for critical severity */
  }
  
  /* Tooltip Styling for Hover */
  .tooltip {
    position: absolute;
    background-color: #333;
    color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 100;
  }
  
  /* Chart Controls */
  .chart-controls {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
  }
  
  .chart-controls button {
    padding: 0.4rem 0.8rem;
  }
  
  /* Responsiveness */
  @media screen and (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    header {
      flex-direction: column;
      text-align: center;
    }
  
    nav {
      position: static;
      width: 100%;
      box-shadow: none;
      padding: 1rem 0;
    }
  }
  
  /* Position the Create Organization button higher up */
  .create-organization {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
  }
  
  .organization-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .organization-card {
    background-color: #1e1e30;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Stack buttons with spacing */
  .button-stack {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Adds space between buttons */
    margin-top: 1rem;
  }
  
  .button-stack button {
    width: 100%; /* Make buttons full width within the card */
  }
  
  /* Adjust details section padding */
  .details-section {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #1e1e30;
    border-radius: 8px;
  }
  
  .delete-button {
    background-color: #f05454;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 30px;
  }
  
  .delete-button:hover {
    background-color: #c43b3b;
  }
  
  .settings-button {
    background-color: #0a4ef9;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .settings-button:hover {
    background-color: #2d69ff;
  }
  
  /* Users page header */
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  /* Users table */
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  .users-table th,
  .users-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .users-table th {
    background-color: #333;
    color: #fff;
  }
  
  /* Modal styling */
  .modal {
    padding: 2rem;
    background-color: #1e1e30;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    margin: auto;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Buttons */
  .add-user-button {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    border-radius: 5px;
  }
  
  .users-table button {
    margin-right: 0.5rem;
  }
  
  .user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
  }
  
  .user-action {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Space between dropdown and button */
  }
  
  .user-item select {
    padding: 0.3rem;
    font-size: 0.9rem;
    border-radius: 5px;
  }
  
  .user-item button {
    padding: 0.3rem 0.6rem;
    font-size: 0.9rem;
    border-radius: 5px;
  }
  
  .flag-chart-container {
    margin-top: 2rem;
    background-color: #1e1e2f;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .flag-toggles {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .flag-toggle label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #e0e0e0;
  }
  
  .highlight-active {
    background-color: #4b0082; /* Dark purple for devices with active flags */
  }


  .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    padding: inherit !important; /* Override PicoCSS padding */
    margin: 0 !important; /* Override PicoCSS margin */
    border-radius: inherit !important; /* Override PicoCSS border-radius */
    min-height: auto !important; /* Override PicoCSS min-height */
    line-height: inherit !important; /* Override PicoCSS line-height */
  }

  /* Override PicoCSS styles for MUI components */
  .MuiInputBase-root {
    --pico-form-element-spacing-vertical: 0 !important;
    --pico-form-element-spacing-horizontal: 0 !important;
    --pico-outline-width: 0 !important;
  }

  /* Ensure MUI form controls are not affected by PicoCSS */
  .MuiFormControl-root input,
  .MuiFormControl-root select,
  .MuiFormControl-root textarea {
    --pico-border-width: 0 !important;
    --pico-border-radius: inherit !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  /* Override PicoCSS focus styles for MUI components */
  .MuiOutlinedInput-root:focus-within {
    --pico-outline-width: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }