/* Login.css */

/* Page styling */
.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    background-color: #0e0e14; /* Consistent dark background */
  }
  
  /* Container for the form */
  .login-container {
    width: 400px;
    padding: 2rem;
    background-color: #1e1e30; /* Dark background for the form container */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  }
  
  /* Title styling */
  .login-title {
    color: #f0f0f0; /* Light text color */
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  /* Input group styling */
  .input-group {
    margin-bottom: 1.5rem;
  }
  
  .input-group label {
    display: block;
    color: #e0e0e0; /* Light text color for labels */
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .input-group input {
    width: 100%;
    padding: 0.75rem;
    background-color: #161625; /* Dark input field background */
    color: #e0e0e0; /* Light input text */
    border: 1px solid #444;
    border-radius: 5px;
  }
  
  .input-group input:focus {
    outline: none;
    border-color: #5a5f78; /* Slightly lighter border on focus */
  }
  
  /* Login button styling */
  .login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #3a3f58; /* Dark blue-gray button */
    color: #e0e0e0; /* Light text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #5a5f78; /* Lighten button on hover */
  }
  