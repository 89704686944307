/* Sidebar.css, WTH */ 
.sidebar {
    width: 240px; /* Adjust the width slightly to add some breathing room */
    height: 100vh; /* Full height to cover the page */
    background-color: #1e1e30;
    color: #fff;
    position: fixed; /* Fix the position to the left */
    top: 70px; /* Adjust this based on the height of the header */
    left: 0; /* Keep it flush with the left side */
    padding: 20px; /* Add a bit more padding to the entire sidebar for breathing room */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Optional: Add a slight shadow for depth */
  }
  
  .sidebar h3 {
    margin-bottom: 20px;
    color: #ff4d4f; /* Make the "Menu" title more noticeable */
    font-size: 1.3em; /* Slightly increased font size */
  }
  
  .sidebar-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-links li {
    margin-bottom: 25px; /* Increased spacing between items for more breathing room */
  }
  
  .sidebar-links a {
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.1em; /* Slightly increased font size */
    transition: all 0.3s ease;
  }
  
  .sidebar-links a:hover {
    color: #ff4d4f; /* Highlight on hover */
    text-decoration: underline; /* Underline text on hover */
  }
  