.header {
  background-color: #161625;
  padding: 1.5rem 2rem; /* Increase padding for taller header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo-image {
  height: 50px; /* Adjust logo height to scale with header */
}

.header-title {
  color: #f0f0f0;
  font-size: 2rem; /* Slightly larger font for better proportion */
  font-weight: bold;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #e0e0e0;
}

.auth-links {
  display: flex;
  gap: 1rem;
}

.auth-links a {
  color: #f05454;
  text-decoration: none;
  font-weight: bold;
}

.auth-links a:hover {
  text-decoration: underline;
}

.logout-button {
  background-color: #f05454;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c43b3b;
}
