/* Add spacing between title and create button */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem; /* Increase bottom margin */
  }
  
  /* Create button styling for more prominent look */
  .create-organization-button {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    border-radius: 5px;
    color: #fff;
    background-color: #007bff; /* Update to a prominent color if needed */
    border: none;
    cursor: pointer;
  }
  
  /* Additional hover effect for create button */
  .create-organization-button:hover {
    background-color: #0056b3;
  }
  
  /* Vertical button stack in each organization card */
  .button-stack {
    display: flex;
    flex-direction: column;
    gap: 0.75rem; /* Add more space between buttons */
    margin-top: 1rem;
  }
  
  .button-stack button {
    width: 100%; /* Full width for consistent alignment */
    padding: 0.5rem; /* Add padding to match design */
    font-size: 0.9rem; /* Adjust font size for readability */
    border-radius: 5px;
  }
  