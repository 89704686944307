/* Layout container */
body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Header and Sidebar fixed */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.sidebar {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  width: var(--sidebar-width);
  z-index: 5;
}

/* Main content container */
.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: var(--sidebar-width);
  margin-top: var(--header-height);
  overflow: hidden;
}

/* Scrollable content area */
.content-scrollable {
  overflow-y: auto;
  padding: 1.5rem;
  max-height: calc(100vh - var(--header-height));
}

/* Individual sections */
.device-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.device-card {
  background-color: #1e1e2f;
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Sensors and Flags Sections */
.sensors-section, .flags-section {
  background: var(--pico-background);
  border-radius: var(--pico-radius);
  padding: 1rem;
  text-align: left;
}

.sensors-section h3, .flags-section h3 {
  margin-bottom: 1rem;
}

/* Remove list dots specifically for sensors and flags */
.sensors-list, .flags-list {
  list-style-type: none !important; /* Force remove dots */
  padding: 0;
  margin: 0;
}

.sensor-item, .flag-item {
  margin-bottom: 1rem;
  padding-left: 0; /* Reset any padding that could push items to the right */
}

.sensor-card, .flag-card {
  background-color: #2b2b3c;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  font-size: 1rem;
}

/* Footer button styling */
.device-footer {
  text-align: center;
  margin-top: 1.5rem;
  gap: 1rem; /* Adds space between buttons */
}

.button-stack {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.settings-button, .firmware-button, .delete-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.settings-button {
  background-color: #0088cc;
  color: white;
}

.settings-button:hover {
  background-color: #006699;
}

.firmware-button {
  background-color: #5cb85c;
  color: white;
}

.firmware-button:hover {
  background-color: #4cae4c;
}

.delete-button {
  background-color: #d9534f;
  color: white;
}

.delete-button:hover {
  background-color: #c9302c;
}

li {
  margin-bottom: 1rem;
  padding-left: 10px;
  list-style: none;
}

.connected-devices-list {
  list-style-type: none;
  padding-left: 0;
}

.connected-devices-list li {
  font-size: 1em;
  margin-bottom: 5px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 1rem;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: #e74c3c;
  font-weight: 500;
}

.device-info {
  font-size: 0.9em;
  color: #666;
  align-items: center;
}