.device-firmware-manager {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #e0e0e0;
}

.device-firmware-manager h3 {
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 1.75rem;
}

.device-firmware-manager h4 {
  margin: 15px 0;
  color: #ffffff;
  font-size: 1.3rem;
}

.header-controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.refresh-button {
  padding: 8px 16px;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.refresh-button:hover:not(:disabled) {
  background-color: #3498db;
}

.refresh-button:disabled {
  background-color: rgba(52, 152, 219, 0.4);
  cursor: not-allowed;
}

.loading {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #cccccc;
}

.no-devices-message {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  color: #e0e0e0;
}

/* OTA Updates Section */
.ota-updates-section {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.ota-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  color: #e0e0e0;
}

.ota-table th,
.ota-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ota-table th {
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 600;
  color: #ffffff;
}

/* Status-specific colors */
.ota-status-1 {
  background-color: rgba(52, 152, 219, 0.2); /* Waiting - blue */
}

.ota-status-2 {
  background-color: rgba(241, 196, 15, 0.2); /* Downloading - yellow */
}

.ota-status-3 {
  background-color: rgba(241, 196, 15, 0.2); /* Verifying - yellow */
}

.ota-status-4 {
  background-color: rgba(46, 204, 113, 0.2); /* Success - green */
}

.ota-status-5 {
  background-color: rgba(231, 76, 60, 0.2); /* Failed - red */
}

/* Progress bar */
.progress-bar-container {
  position: relative;
  width: 100%;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #2ecc71;
  transition: width 0.3s ease;
}

.progress-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
}

.error-message {
  color: #e74c3c;
  font-weight: 500;
}

/* Original styles for device selection */
.device-selection {
  margin-bottom: 25px;
}

.select-all {
  margin-bottom: 10px;
  text-align: right;
}

.select-all-button {
  padding: 8px 16px;
  background-color: #34495e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.select-all-button:hover {
  background-color: #2c3e50;
}

.devices-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  color: #e0e0e0;
}

.devices-table th,
.devices-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.devices-table th {
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 600;
  color: #ffffff;
}

.devices-table tr {
  background-color: rgba(0, 0, 0, 0.2);
}

.devices-table tr:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.devices-table input[type="checkbox"] {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.firmware-update-section {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 15px;
  margin-top: 25px;
}

.update-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;
}

.update-to-latest,
.update-to-specific {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: rgba(255, 255, 255, 0.03);
  padding: 15px;
  border-radius: 8px;
}

.firmware-selection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.form-group label {
  margin-bottom: 10px;
  font-weight: 500;
  color: #e0e0e0;
  font-size: 1rem;
}

.select-container {
  display: flex;
  gap: 10px;
  width: 100%;
}

.branch-select,
.firmware-select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.3);
  color: #e0e0e0;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
}

.branch-select:focus,
.firmware-select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 1px #3498db;
}

.branch-select option,
.firmware-select option {
  background-color: #1e1e1e;
  color: #e0e0e0;
}

.update-button {
  padding: 12px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  width: fit-content;
}

.update-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.update-button:disabled {
  background-color: rgba(52, 152, 219, 0.4);
  cursor: not-allowed;
}

.hint {
  color: #bdc3c7;
  font-style: italic;
  margin: 0;
}

.warning-message {
  background-color: rgba(230, 126, 34, 0.2);
  border-left: 4px solid #e67e22;
  padding: 10px 15px;
  border-radius: 4px;
}

.empty-selection-message {
  color: #bdc3c7;
  text-align: center;
  padding: 15px;
} 