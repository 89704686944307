/* Devices.css */
.devices-page {
    padding: 40px;
    color: #ffffff; /* Text color to contrast against the dark background */
  }
  
  .devices-page h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 30px;
    color: #ff4d4f; /* Highlighted color to match with sidebar links */
    text-align: left; /* Align to make it look more professional */
  }
  
  .device-list {
    display: grid; /* Use grid to create a flexible layout for device cards */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px; /* Space between the device cards */
  }
  
  .device-card {
    background-color: #2b2b3f;
    border-radius: 10px;
    padding: 20px;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .device-card:hover {
    transform: translateY(-5px); /* Add a subtle lift effect on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhance the shadow on hover */
  }
  
  .device-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .device-card p {
    font-size: 1em;
    margin-bottom: 10px;
  }
  