.settings-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #444;
  padding-bottom: 1rem;
}

.settings-header h2 {
  margin: 0;
  color: #eaeaea;
}

.device-info {
  display: flex;
  flex-direction: column;
  text-align: right;
  color: #aaa;
}

.settings-content {
  margin-bottom: 2rem;
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.setting-item {
  display: flex;
  flex-direction: column;
  background-color: #2b2b3d; /* Darker background for better contrast */
  padding: 1rem;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.setting-item label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #dcdcdc; /* Softer label color for contrast */
}

/* Highlight unsynced settings */
.highlight-sync {
  background-color: #2c2c38; /* Dark gray background */
  border-left: 4px solid #646cff; /* Soft blue border for contrast */
}

.highlight-sync label {
  color: #809bff; /* Muted blue for highlighted label text */
}

.highlight-sync input,
.highlight-sync select {
  border-color: #646cff; /* Soft blue border for inputs in highlight mode */
  background-color: #1e1e2c; /* Dark input background for consistency */
  color: #e0e0e0; /* Light text for inputs */
}

.sync-indicator {
  font-size: 0.85em;
  color: #d0d7ff; /* Lighter blue text for sync status */
  margin-top: 4px;
  font-weight: bold;
  text-align: right; /* Align status text to the right */
}

.settings-actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-group {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.settings-actions .save-button {
  padding: 10px 20px;
  background-color: #646cff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.settings-actions .reboot-button {
  padding: 10px 20px;
  background-color: #ff6464;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.settings-actions .save-button:hover:not(:disabled) {
  background-color: #7c82ff;
}

.settings-actions .reboot-button:hover:not(:disabled) {
  background-color: #ff7c7c;
}

.settings-actions .save-button:disabled,
.settings-actions .reboot-button:disabled {
  background-color: #4a4a6a;
  cursor: not-allowed;
  opacity: 0.7;
}

.settings-note {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #ffc107;
  font-style: italic;
  font-size: 0.9rem;
}

.disconnected-warning {
  margin-top: 1rem;
  color: #ff7070;
  font-style: italic;
}

.loading-message,
.error-message,
.no-settings,
.unknown-device-type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #aaa;
  font-style: italic;
}

.error-message,
.unknown-device-type {
  color: #ff7070;
}

/* General styles for inputs and form controls in dark mode */
input[type="text"],
input[type="number"],
select {
  background-color: #1a1a24; /* Slightly darker than main container */
  border: 1px solid #444; /* Dark border for subtle definition */
  color: #dcdcdc; /* Light text color */
  padding: 0.5rem;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .settings-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .device-info {
    margin-top: 1rem;
    text-align: left;
  }
}
