.device-commands-panel {
  padding: 1rem;
}

.device-commands-panel h3 {
  margin-bottom: 1.5rem;
  color: #333;
}

.command-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form-group select,
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.form-group textarea {
  min-height: 100px;
  font-family: monospace;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox-label input[type="checkbox"] {
  margin: 0;
  width: auto;
}

.send-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  align-self: flex-start;
}

.send-button:hover {
  background-color: #0069d9;
} 