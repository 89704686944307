.device-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 30px;
}

.device-card {
  background-color: #2b2b3f;
  border-radius: 10px;
  padding: 25px;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.device-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.device-card h3 {
  font-size: 1.6em;
  margin-bottom: 15px;
}

.device-link {
  text-decoration: none;
  color: #4da8da;
}

.device-link:hover {
  color: #ffffff;
}

.device-card p {
  font-size: 1em;
  margin-bottom: 10px;
}

.device-card h4 {
  margin-top: 20px;
  font-weight: bold;
  color: #ff4d4f;
}

.device-section {
  margin-top: 15px;
}

.device-section h4 {
  font-size: 1.2em;
  color: #ff4d4f;
  margin-bottom: 5px;
}

.sensor-list, .flag-list {
  display: flex;
  flex-direction: column;
}

.sensor-item, .flag-item {
  font-size: 0.95em;
  margin-bottom: 5px;
}

.device-card--pale {
  opacity: 0.6;
}

.connected-devices-list {
  list-style-type: none;
  padding-left: 0;
}

.connected-devices-list li {
  font-size: 0.95em;
  margin-bottom: 5px;
}
