/* SensorChartCard specific styling */
.sensor-chart-card {
    margin-top: 1rem;
  }
  
  /* Toggle button for chart visibility */
  .toggle-chart-button {
    margin-bottom: 1rem;
  }
  
  /* Card styling for charts to be consistent with general card styles */
  .chart-card {
    padding: 1.5rem;
  }
  