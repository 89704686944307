.controller-settings {
  width: 100%;
}

.controller-settings h3 {
  margin-top: 24px;
  margin-bottom: 16px;
  color: #eaeaea;
  border-bottom: 1px solid #444;
  padding-bottom: 8px;
}

.controller-settings h4 {
  margin-top: 8px;
  margin-bottom: 12px;
  color: #dcdcdc;
}

.controller-settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.controller-settings .setting-item {
  display: flex;
  flex-direction: column;
  background-color: #2b2b3d;
  padding: 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.controller-settings .setting-item label {
  font-weight: bold;
  margin-bottom: 8px;
  color: #dcdcdc;
}

.controller-settings .setting-item input[type="number"] {
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #1a1a24;
  color: #dcdcdc;
  margin-bottom: 8px;
}

.controller-settings .setting-item.checkbox {
  padding: 12px 16px;
}

.controller-settings .setting-item.checkbox label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.controller-settings .setting-item.checkbox input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.controller-settings .setting-item small {
  font-size: 0.85em;
  color: #aaa;
  margin-top: 4px;
}

.controller-settings .setting-group {
  background-color: #2b2b3d;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.controller-settings .setting-group .setting-item {
  margin-bottom: 8px;
}

.settings-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-style: italic;
  color: #aaa;
} 