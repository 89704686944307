.admin-tools-container {
  padding: 20px;
  width: 100%;
  margin: 0;
  position: relative;
  min-height: 100vh;
}

/* Navigation */
.admin-tools-container nav {
  position: relative;
  margin-bottom: 2rem;
  padding: 0;
  width: 100%;
}

.admin-tools-container nav ul {
  display: flex;
  gap: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

.admin-tools-container nav li {
  margin: 0;
  flex: 1;
  border-bottom: 1px solid var(--muted-border-color);
}

.admin-tools-container nav button {
  font-weight: 500;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
}

.admin-tools-container nav button.outline {
  background: none;
  border: none;
  color: var(--muted-color);
}

/* Firmware Tabs */
.firmware-tabs {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.firmware-tabs ul {
  border-bottom: 1px solid var(--muted-border-color);
}

.firmware-tabs li {
  flex: 0 1 auto;
  min-width: 150px;
}

.firmware-tabs button {
  padding: 0.75rem 1.5rem;
  border-radius: 0;
  font-size: 0.95rem;
}

.firmware-tabs button.secondary {
  border-bottom: 3px solid var(--primary-color);
}

.tool-section {
  padding: 0 1rem;
}

/* Forms */
.command-form,
.upload-section form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.checkbox-label {
  display: flex !important;
  align-items: center;
  gap: 8px;
}

.checkbox-label input[type="checkbox"] {
  margin: 0;
}

/* Version Grid */
.version-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

/* Button Group */
.button-group {
  display: flex;
  gap: 0.5rem;
}

.button-group button {
  margin: 0;
  padding: 0.5rem 1rem;
}

/* Responsive */
@media (max-width: 768px) {
  .admin-tools-container {
    padding: 10px;
  }

  .tool-section {
    padding: 15px;
  }

  .version-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .firmware-tabs li {
    min-width: 120px;
  }
  
  .firmware-tabs button {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
  }
} 