/* App.css */
.app-layout {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
  }
  
  .header {
    flex-shrink: 0; /* Prevent header from shrinking */
    height: 70px; /* Adjust the height to match sidebar's top */
    display: flex;
    align-items: center;
    background-color: #1a1a2e;
    color: #ffffff;
    padding: 0 20px;
  }
  
  .main-content {
    display: flex;
    flex-grow: 1;
    padding-left: 220px; /* To prevent the content from overlapping with the sidebar */
  }
  
  .content-wrapper {
    flex-grow: 1; /* Allow the content to grow and take up available space */
    overflow-y: auto; /* Allow vertical scrolling */
    padding: 2rem;
    background-color: #0f0f1c; /* Background color to match the dark theme */
    color: #f0f0f0;
  }
  